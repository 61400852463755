import { Component, PropsWithChildren } from "react";

// This tells typescript to treat the web component tag as any other valid html tag,
// otherwise you will recieve typescript errors
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "pov-depth-player": any;
    }
  }
}

type Props = PropsWithChildren<{}>;

let totalMounted = 0;
const script = document.createElement("script");
script.src = "/povDepthPlayer.js";
script.async = true;

export class POVDepthPlayer extends Component<Props> {
  componentDidMount() {
    if (totalMounted === 0) {
      document.body.appendChild(script);
    }
    totalMounted++;
  }

  componentWillUnmount() {
    totalMounted--;
    if (totalMounted === 0) {
      document.body.removeChild(script);
    }
  }

  render() {
    return (
      <pov-depth-player
        {...this.props}
      >
        {this.props.children}
      </pov-depth-player>
    )
  }
}
import { Component } from "react";

export class PrivacyPolicy extends Component {

  componentDidMount(): void {
    document.title = "VolXSpace Privacy Policy";
  }

  render() {
    return (
      <div className="container">
        <h1>VolXSpace Privacy Policy</h1>
        <p>This Privacy Policy describes how VolXSpace ("we", "us", or "our") handles information when you use our media player for VR and AR, VolXSpace (the "Service").</p>

        <h2>Information Collection and Use</h2>
        <p>We do not collect any personal information about users of the VolXSpace media player. We do not gather, store, or process any data that could be used to identify individuals.</p>

        <h2>Access to Exclusive Content</h2>
        <p>VolXSpace provides access to exclusive content through our Patreon campaign and other creators' Patreon campaigns. When users support our Patreon campaign, they gain access to exclusive content from a variety of creators. Supporting a creator's Patreon campaign grants users access to all content created using that creator's source material.</p>

        <h2>Use of Patreon API</h2>
        <p>VolXSpace uses the Patreon API to access information about users' pledges. This includes the campaigns that the user has pledged to, the amount pledged to each campaign, and the tiers of access granted.</p>
        <p>When authenticating with Patreon, users are prompted to grant permission for the following information:</p>
        <ul>
          <li>View Patreon identity info, including full name, account status, and connected social accounts.</li>
          <li>View Patreon identity info, including full name, account status, connected social accounts, and pledges, including status, amount, and history.</li>
        </ul>
        <p>We only utilize the information necessary to provide access to exclusive content and do not store any additional data obtained from Patreon.</p>

        <h2>User Interaction</h2>
        <p>Users cannot interact with other users on the VolXSpace platform. We do not facilitate communication or sharing of information between users.</p>

        <h2>Contact Information</h2>
        <p>To contact us, users can reach out to us through our Twitter: <a href="https://x.com/voluspace_vr">VolXSpace on Twitter</a>.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on the VolXSpace website.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us through our Twitter: <a href="https://x.com/voluspace_vr">VolXSpace on Twitter</a>.</p>
      </div>
    );
  }
}
import { Component, PropsWithChildren } from "react";

// This tells typescript to treat the web component tag as any other valid html tag,
// otherwise you will recieve typescript errors
declare global {
    namespace JSX {
        interface IntrinsicElements {
            "volxspace-player": any;
        }
    }
}

type Props = PropsWithChildren<{
    "content": string,
    "ios-redirect"?: string,
    "id"?: string,
    "fullscreen"?: string,
}>;

let totalMounted = 0;
const script = document.createElement("script");
script.src = "/internal.js";
script.async = true;

export class VolXSpacePlayerInternal extends Component<Props> {
    componentDidMount() {
        if (totalMounted === 0) {
            document.body.appendChild(script);
        }
        totalMounted++;
    }

    componentWillUnmount() {
        totalMounted--;
        if (totalMounted === 0) {
            document.body.removeChild(script);
        }
    }

    render() {
        return (
            <volxspace-player
                {...this.props}
            >
                {this.props.children}
            </volxspace-player>
        )
    }
}
import { Component } from "react";

export class TermsOfService extends Component {

  componentDidMount(): void {
    document.title = "VolXSpace Terms of Service";
  }

  render() {
    return (
      <div className="container">
        <h1>VolXSpace Terms of Service</h1>
        <p><strong>Effective Date:</strong> June 2, 2024</p>
        <p>Welcome to VolXSpace, a media player dedicated to providing users with immersive experiences through VR and AR technology by enabling the viewing of volumetric videos. By accessing or using VolXSpace, you agree to comply with and be bound by the following Terms of Service ("Terms"). Please read these Terms carefully before using our platform.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using VolXSpace, you accept and agree to be bound by these Terms, as well as our Privacy Policy. If you do not agree to these Terms, you may not use VolXSpace.</p>

        <h2>2. Description of Service</h2>
        <p>VolXSpace offers a unique media player designed for VR and AR environments, allowing users to experience volumetric videos in an immersive way. Our platform features a content browser filled with videos compatible with our technology, where the source material is created by various artists and creators.</p>
        <p>Users can gain access to exclusive content in two ways:</p>
        <ul>
          <li>By supporting our Patreon campaign, users receive access to exclusive content from a range of different creators. This does not include all exclusive content available on the platform.</li>
          <li>By supporting individual creators' Patreon campaigns, users gain access to all content made using that particular creator's source material. Exclusive content in this case is limited to videos where the source material was created by the supported creator.</li>
        </ul>

        <h2>3. Use of Patreon API</h2>
        <p>When you authenticate with Patreon, we request the following permissions:</p>
        <ul>
          <li>View Patreon identity info, including full name, account status, and connected social accounts.</li>
          <li>View Patreon identity info, including full name, account status, connected social accounts, and pledges, including status, amount, and history.</li>
        </ul>
        <p><strong>Please note:</strong> VolXSpace only utilizes the following information:</p>
        <ul>
          <li>The campaigns you are or have pledged to.</li>
          <li>How much you are pledging to each campaign.</li>
          <li>The tiers you have access to.</li>
        </ul>
        <p>We do not use any additional information provided by Patreon.</p>

        <h2>4. User Conduct</h2>
        <p>You agree to use VolXSpace responsibly and in a manner that does not disrupt the platform or its services. Prohibited actions include, but are not limited to:</p>
        <ul>
          <li>Attempting to interfere with or disrupt the integrity or performance of the platform, including through distributed denial-of-service (DDoS) attacks.</li>
          <li>Accessing or using the platform through any automated means (such as bots or scripts) without our prior written permission.</li>
          <li>Attempting to gain unauthorized access to any part of the platform or any systems or networks connected to the platform.</li>
        </ul>

        <h2>5. Privacy</h2>
        <p>Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and disclose information about you.</p>

        <h2>6. Intellectual Property</h2>
        <p>All content on VolXSpace, including text, graphics, logos, and images, is the property of VolXSpace or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from this content without express written permission from VolXSpace.</p>

        <h2>7. Disclaimer of Warranties</h2>
        <p>VolXSpace is provided on an "as is" and "as available" basis. We do not warrant that the platform will be uninterrupted or error-free. To the fullest extent permitted by law, VolXSpace disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.</p>

        <h2>8. Limitation of Liability</h2>
        <p>To the maximum extent permitted by applicable law, VolXSpace shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
        <ul>
          <li>Your use or inability to use VolXSpace.</li>
          <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
          <li>Any interruption or cessation of transmission to or from our services.</li>
          <li>Any bugs, viruses, Trojan horses, or the like that may be transmitted to or through our services by any third party.</li>
          <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through VolXSpace.</li>
        </ul>

        <h2>9. Indemnification</h2>
        <p>You agree to indemnify and hold harmless VolXSpace, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees) arising from your use of and access to VolXSpace, your violation of these Terms, or your infringement of any intellectual property or other right of any person or entity.</p>

        <h2>10. Changes to the Terms</h2>
        <p>VolXSpace reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the platform. Your continued use of VolXSpace following the posting of changes constitutes your acceptance of such changes.</p>

        <h2>11. Termination</h2>
        <p>We may terminate or suspend your access to VolXSpace immediately, without prior notice or liability, if you breach any of these Terms. Upon termination, your right to use VolXSpace will cease immediately.</p>

        <h2>12. Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which VolXSpace operates, without regard to its conflict of law provisions.</p>

        <h2>13. Contact Information</h2>
        <p>If you have any questions about these Terms, please contact us by sending a message on Twitter: <a href="https://x.com/voluspace_vr">VolXSpace on Twitter</a>.</p>

        <p>By using VolXSpace, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
      </div>
    );
  }
}